
import "./style.css";
import * as THREE from "three";
import { ORBIT_CONTROLS, RENDER, objects, speed, radius, center, carouselGroup,  } from "./script";
import { SCENE } from "./scene";
import gsap from "gsap";

/**
 * =====================================================================
 * particles around logo ----------------------
 */

const textureLoader = new THREE.TextureLoader();
const particleTexture = textureLoader.load('static/images/star_04.png');

// cilindro de referencia no borrar
const cylinderGeometry = new THREE.CylinderGeometry(1, 1, 2, 32);
const cylinderMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
//SCENE.add(cylinder);

// ... Your cylinder and scene setup ...

const particleCount = 500;
const particles = new THREE.BufferGeometry();
const positions = new Float32Array(particleCount * 3);

for (let i = 0; i < particleCount; i++) {
  const i3 = i * 3;

  // Generate initial positions for particles outside the cylinder
  let theta = Math.random() * Math.PI * 2;
  let radius = 1; // ajustar radio mas abajo
  positions[i3] = Math.cos(theta) * radius;
  positions[i3 + 1] = Math.random() * 10; // Start above the cylinder
  positions[i3 + 2] = Math.sin(theta) * radius;
}

particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));

const particleMaterial = new THREE.PointsMaterial({
//color: new THREE.Color('#ffffff'), // Set color
  size: 0.3,
  sizeAttenuation: true, // Enable size attenuation
  alphaMap: particleTexture, // Set alpha map for transparency
  map: particleTexture, // Set texture map
  alphaTest: 0.15 // Alpha test to discard semi-transparent particles
});

const startTime = Date.now(); // Record the start time when you start your animation

const particleSystem = new THREE.Points(particles, particleMaterial);

const maxHeight = 2.1; // Set your maximum height for the particles

let emitParticles = false; // Flag to determine if particles should start emitting


function animateParticles() {
  const time = Date.now() * 0.001;  // Adjust time scaling for smoother animation

  // Check if 3 seconds have passed to start emitting particles
  if (Date.now() - startTime > 3000) {
    emitParticles = true;
  }

  if (emitParticles) {
    const positionAttribute = particles.getAttribute('position');
    for (let i = 0; i < particleCount; i++) {
      const i3 = i * 3;

      // Update the Y position of the particle for the fire-like effect
      positionAttribute.array[i3 + 1] += Math.random() * 0.2;

      // Reset the particle position to a starting position if it goes too high
      if (positionAttribute.array[i3 + 1] > maxHeight) {
        let theta = Math.random() * Math.PI * 2;
        let radius = 1.9; // Adjust this radius to control the distance from the cylinder
        positionAttribute.array[i3] = Math.cos(theta) * radius;
        positionAttribute.array[i3 + 1] = 0.7; // Start above the cylinder
        positionAttribute.array[i3 + 2] = Math.sin(theta) * radius;
      }
    }

    positionAttribute.needsUpdate = true;
  }
}

// end  of particles around logo -------------------


/**
 * =====================================================================
 * exports
 */
export {
    animateParticles,
    particleSystem,
    particles,
    particleMaterial,
  };
  
