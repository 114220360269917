import "./style.css";
import * as THREE from "three";
import { CAMERA } from "./camera";
import { objects } from "./script";
import {     loadModalContentHome,
  loadModalContentServices,
  loadModalContentPortfolio,
  loadModalContentLab,
  loadModalContentContact, } from "./modals";
import {
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection,
} from "./carousel";
import gsap from "gsap";
import {
  playAudio,
  playSound,
  soundPlayed,
  audioLoader,
  sound,
  listener,
  AUDIO_LISTENER,
  objAudio1,
  objAudio2,
} from "./audio";
import { SCENE } from "./scene";

///--------------------------------------------------------------------------

// backdrop prevents click outside the modal windows
let currentModal = null;
let currentModalBackdrop = null;

// Define a global variable to track if a modal is open
let isModalOpen = false;


// Function to check if the device is a mobile device based on screen size
function isMobileDevice() {
  return window.innerWidth <= 768; // Adjust the breakpoint as needed
}

// // Call the initial check to remove roll-over event listener if on mobile
  // if (isMobileDevice()) {
  //   window.removeEventListener(onDocumentMouseMove, onRollOver, false);
  // }


// click function  ------------------------------------------------------------------------------------------------
function onDocumentMouseDown(event) {
  // Check if a modal is open, if so, return early and prevent interaction with the scene
  if (isModalOpen) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  var vector = new THREE.Vector3(
    (event.clientX / window.innerWidth) * 2 - 1,
    -(event.clientY / window.innerHeight) * 2 + 1,
    0.5
  );
  vector.unproject(CAMERA);
  var raycaster = new THREE.Raycaster(
    CAMERA.position,
    vector.sub(CAMERA.position).normalize()
  );
  var intersects = raycaster.intersectObjects(objects);
  if (intersects.length > 0) {
    var clickedObject = intersects[0].object;

    // Contact ---------------
    if (clickedObject.userData.name === "contactSection") {
            // Check if it's a mobile device
            if (isMobileDevice()) {
              // Remove the text elements with the class ".text"
              const textElements = document.getElementsByClassName("text");
              for (let i = 0; i < textElements.length; i++) {
                textElements[i].style.display = "none";
              }
            }

              // Load the modal content for Object 1
loadModalContentContact();
      
      $("#Modal-Contact").modal("show");
      currentModal = "#Modal-Contact";
      currentModalBackdrop = "#modalBackdrop"; // Set the current modal backdrop
      objAudio1();
      isModalOpen = true; // Set isModalOpen to true when the modal is open
      document.body.style.cursor = "auto";

      // Services  ---------------
    } else if (clickedObject.userData.name === "servicesSection") {
                  // Check if it's a mobile device
                  if (isMobileDevice()) {
                    // Remove the text elements with the class ".text"
                    const textElements = document.getElementsByClassName("text");
                    for (let i = 0; i < textElements.length; i++) {
                      textElements[i].style.display = "none";
                    }
                  }
  // Load the modal content for Object 1
loadModalContentServices();

      $("#Modal-Services").modal("show");
      currentModal = "#Modal-Services";
      currentModalBackdrop = "#modalBackdrop";
      objAudio1();
      isModalOpen = true;
      document.body.style.cursor = "auto";

      // Portfolio click ---------------
    } else if (clickedObject.userData.name === "portfolioSection") {
                  // Check if it's a mobile device
                  if (isMobileDevice()) {
                    // Remove the text elements with the class ".text"
                    const textElements = document.getElementsByClassName("text");
                    for (let i = 0; i < textElements.length; i++) {
                      textElements[i].style.display = "none";
                    }
                  }

                    // Load the modal content for Object 1
loadModalContentPortfolio();

      $("#Modal-Portfolio").modal("show");
      currentModal = "#Modal-Portfolio";
      currentModalBackdrop = "#modalBackdrop";
      objAudio1();
      isModalOpen = true;
      document.body.style.cursor = "auto";

      // Lab click ---------------
    } else if (clickedObject.userData.name === "labSection") {
                  // Check if it's a mobile device
                  if (isMobileDevice()) {
                    // Remove the text elements with the class ".text"
                    const textElements = document.getElementsByClassName("text");
                    for (let i = 0; i < textElements.length; i++) {
                      textElements[i].style.display = "none";
                    }
                  }

                    // Load the modal content for Object 1
  loadModalContentLab();

      $("#Modal-Lab").modal("show");
      currentModal = "#Modal-Lab";
      currentModalBackdrop = "#modalBackdrop";
      objAudio1();
      isModalOpen = true;
      document.body.style.cursor = "auto";

   // dodeca click ---------------
} else if (clickedObject.userData.name === "homeSection") {
  // Check if it's a mobile device
  if (isMobileDevice()) {
    // Remove the text elements with the class ".text"
    const textElements = document.getElementsByClassName("text");
    for (let i = 0; i < textElements.length; i++) {
      textElements[i].style.display = "none";
    }
  }

  // Load the modal content for Object 1
  loadModalContentHome();

  // Show the modal
  $("#Modal-Home").modal("show");
  currentModal = "#Modal-Home";
  currentModalBackdrop = "#modalBackdrop";
  objAudio1();
  isModalOpen = true;
  document.body.style.cursor = "auto";
} else {
      // Handle other objects...
    }
  }
}

// Function to handle modal close event and set isModalOpen to false
function closeModal() {
  if (isModalOpen) {
    // Add code to play the sound when the modal is closed
    objAudio2();

  }

  // Check if it's a mobile device and a modal is closed
  if (isMobileDevice() && currentModal) {
    // Remove the text elements with the class ".text"
    const textElements = document.getElementsByClassName("text");
    for (let i = 0; i < textElements.length; i++) {
      textElements[i].style.display = "none";
    }
  }

  isModalOpen = false;
  currentModal = null; // Reset the current active modal
}

// Add an event listener to the modal close button to call closeModal function
$(".modal .close").on("click", function () {
  closeModal();
  // Close the current modal
  if (currentModal) {
    $(currentModal).modal("hide");
  }
});



// roll over function -----------------------------------------------------------------------------------------

function onDocumentMouseMove(event) {
  const mouse = new THREE.Vector2();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1; // ajusto visibilidad del x axe
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1; // ajusto visibilidad del y axe
  // Add the following line to stop the propagation of the mouse event
  event.stopPropagation();

  // Check if a modal is open, if so, return early and do not trigger the sound
  if (isModalOpen) {
    return;
  }


  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, CAMERA);
  const intersects = raycaster.intersectObjects(objects);

  if (intersects.length > 0) {
   document.body.style.cursor = "pointer";
   
    const object = intersects[0].object;
    const objectText = object.userData.name;

    // Show the text on roll overr ---
    switch (objectText) {
      // Add the call to the playSound function for each object
      case "servicesSection":
        gsap.to("#servicesSection", {
          opacity: 1,
          visibility: "hidden",  // de momento estan ocultos --------------
          duration: 0,
        });
        playSound(objectText);
        break;

      case "portfolioSection":
        gsap.to("#portfolioSection", {
          opacity: 1,
          visibility: "hidden",
          duration: 0,
        });
        playSound(objectText);
        break;

      case "labSection":
        gsap.to("#labSection", {
          opacity: 1,
          visibility: "hidden",
          duration: 0,
        });
        playSound(objectText);
        break;

      case "contactSection":
        gsap.to("#contactSection", {
          opacity: 1,
          visibility: "hidden",
          duration: 0,
        });
        playSound(objectText);
        break;

      case "homeSection":
        gsap.to("#homeSection", {
          opacity: 1,
          visibility: "hidden",
          duration: 0,
        });
        playSound(objectText);
        break;
    }

    // Increase the scale of the object
    gsap.to(object.scale, {
      x: 1.3,
      y: 1.3,
      z: 1.3,
      duration: 0.7,
      ease: "power3.out",
    });
  } else {
    document.body.style.cursor = "default";
    // Reset the soundPlayed flag when the mouse moves away from the object
    Object.keys(soundPlayed).forEach((key) => {
      soundPlayed[key] = false;
    });

    // Hide all texts
    gsap.to(".text", {
      opacity: 0,
      visibility: "hidden",
      duration: 0,
    });

    // Reset the scale of all objects
    objects.forEach((object) => {
      gsap.to(object.scale, {
        x: 1.1,
        y: 1.1,
        z: 1.1,
        duration: 0.7,
        ease: "power3.out",
      });
    });
  }
}




export { onDocumentMouseDown, onDocumentMouseMove };
