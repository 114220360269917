import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";

// stats
const STATS = new Stats();
//document.body.appendChild(STATS.dom);

// axes
const AXES_HELPER = new THREE.AxesHelper(20); //largo
AXES_HELPER.position.y = 1;

// grid
const GRID_HELPER = new THREE.GridHelper(20, 10, "white");
GRID_HELPER.material.opacity = 0.5;
GRID_HELPER.material.transparent = true;

export { AXES_HELPER, GRID_HELPER, STATS };
