import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { SCENE } from "./scene";
import { PRELOADER } from "./preloader";
import * as THREE from "three";
import { CAMERA, ORBIT_CONTROLS } from "./camera";
import { RENDER } from "./script";
import gsap from "gsap";

// full paths to the 3d models
//import pantallaModelPath from "../static/models/pantalla.gltf";
//import huskyModelPath from "../static/models/husky.gltf";
import roomModelPath from "../static/models/room.gltf";
import centerModelPath from "../static/models/center.gltf";

const GLTF_LOADER = new GLTFLoader(PRELOADER);

// mixers para cada modelo, que luego tengo que agregar a functions.js
let MIXER1 = null;
let MIXER2 = null;

//---------------------modelo center  -----------------------------
GLTF_LOADER.load(
  centerModelPath,
  function (gltf) {
    gltf.scene.traverse(function (node) {
      // necesario para agregar shadow
      if (node.isMesh) {
        const mesh = node;
        // Access the material and apply custom settings if desired
        // For example, adjust roughness, metalness, etc.
        mesh.material.roughness = 0.2;
        mesh.material.metalness = 0.7;
      }
      if (node.isMesh) { // RBG color code
        const materials = Array.isArray(node.material)
          ? node.material
          : [node.material];

        materials.forEach((material) => {
          if (material.map) {
            // Set the encoding for each valid texture map
            material.map.encoding = THREE.LinearEncoding;
          }
        });
      }

      if (node.isMesh) {
        node.receiveShadow = true;
      }

      if (node.isMesh) {
        node.castShadow = true;
      }


    });
    gltf.scene.scale.set(0.0027, 0.0027, 0.0027);
    gltf.scene.position.x = 0;
    gltf.scene.position.y = 1;
    gltf.scene.rotation.set(0, 0, 0);
 SCENE.add(gltf.scene);
  grupo1.add(gltf.scene);
    console.log("modelo center loaded");
  },
  function (xhr) {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  function (error) {
    console.log("Error loading GLTF model: " + error);
  },
  PRELOADER // PRELOADER as the last argument
);




// ---------------------modelo room  -----------------------------
GLTF_LOADER.load(
  roomModelPath,
  function (gltf) {
    gltf.scene.traverse(function (node) {
      // necesario para agregar shadow
      if (node.isMesh) {
        const mesh = node;
        // Access the material and apply custom settings if desired
        // For example, adjust roughness, metalness, etc.
        mesh.material.roughness = 0.2;
        mesh.material.metalness = 0.4;
      }
      if (node.isMesh) { // RBG color code
        const materials = Array.isArray(node.material)
          ? node.material
          : [node.material];

        materials.forEach((material) => {
          if (material.map) {
            // Set the encoding for each valid texture map
            material.map.encoding = THREE.LinearEncoding;
          }
        });
      }

      if (node.isMesh) {
        node.receiveShadow = true;
      }

      if (node.isMesh) {
        node.castShadow = true;
      }


    });
    gltf.scene.scale.set(0.0027, 0.0027, 0.0027);
    gltf.scene.position.x = 0;
    gltf.scene.position.y = 1;
    gltf.scene.rotation.set(0, 0, 0);
 SCENE.add(gltf.scene);
    console.log("modelo room loaded");
  },
  function (xhr) {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  function (error) {
    console.log("Error loading GLTF model: " + error);
  },
  PRELOADER // PRELOADER as the last argument
);


///----------------------------animas

  // grupo1 // declaro y dentro del modelo lo agrego al grupo
  const grupo1 = new THREE.Group();

  // Set initial position of the group
  grupo1.position.set(0, -3, 0);

  // Add the group to the scene
  SCENE.add(grupo1);

  // Create a new timeline
  const centerMove = gsap.timeline({
    paused: true,
    repeat: 0
  });
 

// Start the rotation animation completes
centerMove.to(grupo1.position, {
 delay: 0,
  duration: 4.5,
  y: 0.6,
  ease: "linear",
  repeat: 0, // Repeat the rotation animation indefinitely
});



export { GLTF_LOADER, MIXER1, MIXER2, centerMove };
