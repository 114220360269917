
import * as THREE from "three";
import { AXES_HELPER, GRID_HELPER, STATS } from "./helpers";
import { SCENE } from "./scene";
import { CAMERA, ORBIT_CONTROLS } from "./camera";

// Enviromental Material ------------------------------------

let tooLazyToHandleLoadingProperly = 0;
const loadingLol = () => tooLazyToHandleLoadingProperly++;
const ENV_URL = 'static/images/env.jpg'; //path to the envmap
const reflectionCube = new THREE.TextureLoader().load(ENV_URL, loadingLol);
const refractionCube = new THREE.TextureLoader().load(ENV_URL, loadingLol);
reflectionCube.mapping = THREE.EquirectangularReflectionMapping;
refractionCube.mapping = THREE.EquirectangularRefractionMapping;
SCENE.background = reflectionCube;
SCENE.environment = reflectionCube;

const geometry = new THREE.SphereGeometry(2, 128, 128);
const base = geometry.attributes.position.array.slice();
const refractionMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xc3e4f9,
  envMap: refractionCube,
  metalness: 1,
  reflectivity: 0,
  refractionRatio: .1,
  roughness: 0,
  side: THREE.DoubleSide
});
const reflectionMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xc3e4f9,
  envMap: reflectionCube,
  envMapIntensity: 1,
  metalness: .35,
  reflectivity: .9,
  roughness: 0,
  side: THREE.DoubleSide,
  transmission: 1,
  transparent: true,
});
const refractionSphere = new THREE.Mesh(geometry, refractionMaterial);
const reflectionSphere = new THREE.Mesh(geometry, reflectionMaterial);

const sphere = new THREE.Object3D();
sphere.add(refractionSphere);
sphere.add(reflectionSphere);
//SCENE.add(sphere); //esfera reflejante
sphere.lookAt(CAMERA.position);
sphere.scale.set(0.5,0.5,0.5);
//CAMERA.lookAt(sphere.position);

// Enviromental Material END ------------------------------------

export {
ENV_URL,
reflectionCube,
refractionCube,
  };