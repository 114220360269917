
import * as THREE from "three";
import {
    FontLoader
  } from "three/src/loaders/FontLoader.js";
  import {TextGeometry} from "three/src/geometries/TextGeometry.js";



import {
    SCENE,
    fog
  } from "./scene";

  import {
    PRELOADER
  } from "./preloader";
  import gsap from "gsap";

 /**
 * Textures
 */
const textureLoader = new THREE.TextureLoader(PRELOADER);
const matcapTexture = textureLoader.load("static/textures/cromo.png");

/// font
const fontLoader = new FontLoader();

// Variables to store the animations
let upAnimation;
let rotationAnimation;

fontLoader.load("static/fonts/ms.json", (font) => {
  // Material
  const material = new THREE.MeshMatcapMaterial({
    matcap: matcapTexture,
    // roughness: 0,
    // metalness: 0.5, 
  });

  // Text
  const textGeometry = new TextGeometry("©", {
    font: font,
    size: 5,
    height: 2,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 20,
    
  });
  textGeometry.center();

  const text = new THREE.Mesh(textGeometry, material);
  SCENE.add(text);
  text.castShadow = true;
  text.position.set(0, -1.8, 0); // x, y, z
  text.scale.set(0.5, 0.5, 0.3); // x, y, z


  // Create a new timeline for upAnimation
  upAnimation = gsap.timeline({
    paused: true, // Pause the animation initially
    onComplete: () => {
      // Handle completion if needed
   rotationAnimation.play(); // start rotationAnimation on the completion of upAnimation
    },
  });

  // Define the GSAP animation for position
  upAnimation.to(text.position, {
    delay: 0,
    duration: 4.65,
    y: 2.1,
    ease: "linear",
  });

  // Create a new timeline for rotationAnimation
  rotationAnimation = gsap.timeline({
    paused: true, // Pause the animation initially
    repeat: -1, // Repeat the rotation animation indefinitely
  });

  // Start the rotation animation completes
  rotationAnimation.to(text.rotation, {
    delay: 0,
    duration: 3,
    y: Math.PI * -2,
    ease: "linear",
  });
  
});



// fin font --------------------------------

///----------------------------animas

  // grupologo // declaro y dentro del modelo lo agrego al grupo
 // const grupoLogo = new THREE.Group();
 

export {
   fontLoader,
   textureLoader,
   upAnimation,
   rotationAnimation,
  };