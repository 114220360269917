import "./style.css";
import * as THREE from "three";
import { CAMERA, ORBIT_CONTROLS } from "./camera";
import { reflectionCube, refractionCube } from "./envmap";


//------------------------------------------------------------

// Sphere portfolioSection
var texture_sphere2 = new THREE.TextureLoader().load("static/images/2.jpg");
var geometry_sphere2 = new THREE.SphereGeometry(1, 40, 40);
// Create a reflective material
var material_sphere2 = new THREE.MeshStandardMaterial({
  map: texture_sphere2,
  roughness: 0.3,
  metalness: 0.2, // Set metalness to 1 for full reflection
  // emissive: 0x99cc00, // Set the emissive color to white
  // emissiveIntensity: 10, // Adjust the intensity of the emissive glow
 // envMap: reflectionCube
});
const servicesSection = new THREE.Mesh(geometry_sphere2, material_sphere2);
servicesSection.userData.name = "servicesSection";
servicesSection.position.x = Math.cos((1 / 5) * 2 * Math.PI) * 5;
servicesSection.position.z = Math.sin((1 / 5) * 2 * Math.PI) * 5;
servicesSection.position.y = 0.8;
servicesSection.castShadow = true;


// Sphere servicesSection
var texture_sphere = new THREE.TextureLoader().load("static/images/3.jpg");
var geometry_sphere = new THREE.SphereGeometry(1, 40, 40);
// Create a reflective material
var material_sphere = new THREE.MeshStandardMaterial({
  map: texture_sphere,
  roughness: 0.3,
  metalness: 0.2, // Set metalness to 1 for full reflection
  //envMap: /* Load your environment map here */ // You'll need an environment map
});
const portfolioSection = new THREE.Mesh(geometry_sphere, material_sphere);
portfolioSection.userData.name = "portfolioSection";
portfolioSection.position.x = Math.cos((2 / 5) * 2 * Math.PI) * 5;
portfolioSection.position.z = Math.sin((2 / 5) * 2 * Math.PI) * 5 ;
portfolioSection.position.y = 0.8;
portfolioSection.castShadow = true;

// Sphere labSection
var texture_sphere3 = new THREE.TextureLoader().load("static/images/4.jpg");
var geometry_sphere3 = new THREE.SphereGeometry(1, 40, 40);
// Create a reflective material
var material_sphere3 = new THREE.MeshStandardMaterial({
  map: texture_sphere3,
  roughness: 0.3,
  metalness: 0.2, // Set metalness to 1 for full reflection
  //envMap: /* Load your environment map here */ // You'll need an environment map
});
const labSection = new THREE.Mesh(geometry_sphere3, material_sphere3);
labSection.userData.name = "labSection";
labSection.position.x = Math.cos((3 / 5) * 2 * Math.PI) * 5;
labSection.position.z = Math.sin((3 / 5) * 2 * Math.PI) * 5 ;
labSection.position.y = 0.8;
labSection.castShadow = true;

// Sphere contactSection
var texture_sphere4 = new THREE.TextureLoader().load("static/images/5.jpg");
var geometry_sphere4 = new THREE.SphereGeometry(1, 40, 40);
// Create a reflective material
var material_sphere4 = new THREE.MeshStandardMaterial({
  map: texture_sphere4,
  roughness: 0.3,
  metalness: 0.2, // Set metalness to 1 for full reflection
  //envMap: /* Load your environment map here */ // You'll need an environment map
});
const contactSection = new THREE.Mesh(geometry_sphere4, material_sphere4);
contactSection.userData.name = "contactSection";
contactSection.position.x = Math.cos((4 / 5) * 2 * Math.PI) * 5;
contactSection.position.z = Math.sin((4 / 5) * 2 * Math.PI) * 5;
contactSection.position.y = 0.8;
contactSection.castShadow = true;

// Sphere homeSection
var texture_sphere5 = new THREE.TextureLoader().load("static/images/1.jpg");
var geometry_sphere5 = new THREE.SphereGeometry(1, 40, 40);
// Create a reflective material
var material_sphere5 = new THREE.MeshStandardMaterial({
  map: texture_sphere5,
  roughness: 0.3,
  metalness: 0.2, // Set metalness to 1 for full reflection
  //envMap: /* Load your environment map here */ // You'll need an environment map
});
const homeSection = new THREE.Mesh(geometry_sphere5, material_sphere5);
homeSection.userData.name = "homeSection";
homeSection.position.x = Math.cos((5 / 5) * 2 * Math.PI) * 5;
homeSection.position.z = Math.sin((5 / 5) * 2 * Math.PI) * 5 ;
homeSection.position.y = 0.8;
homeSection.castShadow = true;


export {
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection,
};

