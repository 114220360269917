import * as THREE from 'three';
import gsap from 'gsap' // importo green sock para animaciones
import './style.css';


    // Get references to the elements
const progressBar = document.getElementById('progress-bar');
const progressText = document.getElementById('progress-text');
const intro = document.getElementById('intro');
const ingresarBtn = document.getElementById('ingresar');
const preloader = document.getElementById('preloader');

// Create a new LoadingManager
const PRELOADER = new THREE.LoadingManager();

// Preload real

PRELOADER.onProgress = function (url, loaded, total) {
  progressBar.style.width = `${(loaded / total) * 100}%`;
  progressText.textContent = `[ LOADING:  ${Math.floor((loaded / total) * 100)}% ]`;
};

PRELOADER.onLoad = function () {
  gsap.to(preloader, {
    opacity: 0,
    duration: 0.5, // Set a fade-out only for the bar and preload text
    onComplete: () => {
      preloader.style.display = 'none';

      
      // gsap.to(ingresarBtn, {
      //   opacity: 1,
      //   duration: 2,
      //   ease: "power3.out",
      // });
      
      intro.style.display = 'flex';
 ingresarBtn.style.display = 'block'; // Show the button after preload completion
      console.log('Preload: Files loaded');


    },
  });
};




export {
    PRELOADER,
    progressBar,
    intro,
    ingresarBtn,
    preloader,
    progressText,
};


