//////////////////////////////////////////////

import "./style.css";
import * as THREE from "three";
import { AXES_HELPER, GRID_HELPER, STATS } from "./helpers";
import { SCENE } from "./scene";
import { ENV_URL, reflectionCube, refractionCube } from "./envmap";
import { fontLoader, upAnimation, rotationAnimation } from "./fonts";
import { AMBIENT_LIGHT, DIRECTIONAL_LIGHT } from "./lights";
import { CAMERA, ORBIT_CONTROLS } from "./camera";
import { SIZES, CANVAS } from "./others";
import { ANIMATE } from "./functions";
import {
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection,
} from "./carousel";
import {
  onDocumentMouseDown,
  onDocumentMouseMove,
  currentModal,
} from "./functions-carousel";
import gsap from "gsap";

import {
  animateParticles,
  particleSystem,
  particles,
  particleMaterial,
} from "./particles";

//import * as dat from "dat.gui";
import { sound,} from "./audio";
import { centerMove } from "./models";
import {
  progressBar,
  intro,
  ingresarBtn,
  preloader,
  progressText,
} from "./preloader";

import {
  loadModalContentHome,
  loadModalContentServices,
  loadModalContentPortfolio,
  loadModalContentLab,
  loadModalContentContact,
} from "./modals";

/**
 * =====================================================================
 * // listeners varios para intro y preload
 */

// carousel create
function createObject(name, textureURL, geometry) {
  const texture = new THREE.TextureLoader().load(textureURL);
  const material = new THREE.MeshBasicMaterial({
    map: texture,
  });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.userData.name = name; // user data name
  mesh.userData.URL = textureURL; // user data link
  return mesh;
}

const objects = [];
objects.push(servicesSection);
objects.push(portfolioSection);
objects.push(labSection);
objects.push(contactSection);
objects.push(homeSection);

// funcion para disable click
const objectsBehindModal = [
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection,
];

// codigo carousel
document.addEventListener("mousemove", onDocumentMouseMove, false);
document.addEventListener("mousedown", onDocumentMouseDown, false);

// Before showing the modal, disable pointer events for objects behind the modal
objectsBehindModal.forEach((object) => {
  object.userData.class = "object-behind-modal";
});

/**
 * =====================================================================
 * // scene parts
 */

SCENE.add(
  DIRECTIONAL_LIGHT, // with shadows
  AMBIENT_LIGHT, // esta no tiene shadows
  // AXES_HELPER,
  // GRID_HELPER,
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection
);

// Create a parent Group object
const carouselGroup = new THREE.Group();
carouselGroup.scale.set(0, 0, 0); // x, y, z
carouselGroup.position.y = -3;

// //animacion tips // drag to move --------------
let aniWp = document.querySelector("#whatsapp-btn"); // referencia al elemento
let tip1 = document.querySelector("#tip1"); // referencia al elemento
let tip2 = document.querySelector("#tip2"); // referencia al elemento

/**
 * =====================================================================
 * // listener al click event then play everything
 */
ingresarBtn.addEventListener("click", () => {
  gsap.to(intro, {
    opacity: 0,
    duration: 1,
    onComplete: () => {
      intro.style.display = "none";

    },
  });

  // Start the animation center
  upAnimation.play(); // logo + rotationAnimation;
  centerMove.play(); // modelo move up

  // Start the audio button ----------------------------------------------------
  const audioButton = document.getElementById("audioButton");
  let audioElement = new Audio("static/audio/loop.mp3"); // Create audio element when the script loads
  audioElement.loop = true;

  // Start audio playback when the scene loads
  audioElement.play();

  let isPlaying = true; // Since audio is already playing when the scene loads

  // Update button text initially with Font Awesome icons
  audioButton.innerHTML = '<i class="fa fa-volume-off"></i>'; // Assuming "fa-pause" is the pause icon

  // Toggle audio playback when the button is clicked
  audioButton.addEventListener("click", () => {
    if (isPlaying) {
      audioElement.pause();
      audioButton.innerHTML = '<i class="fa fa-volume-up"></i>'; //  <i class="fa-solid fa-volume-xmark"></i>
    } else {
      audioElement.play();
      audioButton.innerHTML = '<i class="fa fa-volume-off"></i>'; //
    }

    isPlaying = !isPlaying;
  }); // fin de audio button ----------------------

  // Animate tip1 fade in and slide from bottom to the center
  gsap.fromTo(
    aniWp,
    {
      opacity: 0,
      x: 100, // Slide from 100px below the center
      ease: "power3.out",
    },
    {
      opacity: 1,
      x: -100, // Slide to the center of the screen
      duration: 1,
      ease: "power3.out",
      delay: 2, // Delay the start of animation by 10 seconds
      onComplete: function () {
        // Disappear after sliding to the center
        gsap.to(aniWp, {
          opacity: 1,
          x: -100, // Slide up and disappear
          duration: 3,
          ease: "power3.out",
          delay: 1, // Delay after appearing for 1 second
        });
      },
    }
  );

  gsap.fromTo(
    audioButton,
    {
      opacity: 0,
      x: -10, // Slide from 100px below the center
      ease: "power3.out",
    },
    {
      opacity: 1,
      x: 70, //
      duration: 1,
      ease: "power3.out",
      delay: 2, // Delay the start of animation by 10 seconds
      onComplete: function () {
        // Disappear after sliding to the center
        gsap.to(aniWp, {
          opacity: 1,
          x: -100, // Slide up and disappear
          duration: 3,
          ease: "power3.out",
          delay: 1, // Delay after appearing for 1 second
        });
      },
    }
  );

  //  Animate tip1 fade in and slide from bottom to the center
  gsap.fromTo(
    tip1,
    {
      opacity: 0,
      x: -200, // Slide from 100px below the center
      ease: "power3.out",
    },
    {
      opacity: 1,
      x: 170, // Slide to the center of the screen
      duration: 1,
      ease: "power3.out",
      delay: 10, // Delay the start of animation by 10 seconds
      onComplete: function () {
        // Disappear after sliding to the center
        gsap.to(tip1, {
          opacity: 0,
          x: -200, // Slide up and disappear
          duration: 1,
          ease: "power3.out",
          delay: 1, // Delay after appearing for 1 second
        });
      },
    }
  );

  // Animate tip2 after 5 seconds of delay
  gsap.fromTo(
    tip2,
    {
      opacity: 0,
      x: -200, // Slide from 100px below the center
      ease: "power3.out",
    },
    {
      opacity: 1,
      x: 170, // Slide to the center of the screen
      duration: 1,
      ease: "power3.out",
      delay: 15, // Delay start of animation by 5 seconds
      onComplete: function () {
        // Disappear after sliding to the center
        gsap.to(tip2, {
          opacity: 0,
          x: -200, // Slide up and disappear
          duration: 1,
          ease: "power3.out",
          delay: 1, // Delay after appearing for 1 second
        });
      },
    }
  );

  // bola ani inicial scale // esta anima tiene que estar para evitar el click
  gsap.fromTo(
    carouselGroup.scale,
    {
      x: 0.00001,
      y: 0.00001,
      z: 0.00001,
    },
    {
      delay: 3,
      duration: 0.2,
      x: 1,
      y: 1,
      z: 1,
      ease: "power3.out",
    }
  );



// Animation to move the carousel and play audio in sync
gsap.fromTo(
  carouselGroup.position,
  {
    y: -4, // Start from a position below the visible area
  },
  {
    delay: 4, // Delay before animation starts
    duration: 2,
    y: 0, // Move to the Y position where you want it to appear
    ease: "power2.out",
    onComplete: function () {
      // Animation complete logic (if needed)

    }
  }
);

  

  // particulas start here /////////

  SCENE.add(particleSystem);

  // Initial opacity of the particle system (0)
  particleSystem.material.opacity = 0;

  // GSAP animation to gradually increase opacity to 1
  gsap.to(particleSystem.material, {
    duration: 2,
    opacity: 0.8,
    ease: "power2.inOut",
    delay: 3.5,
  });
}); /// fin de todo lo que se ejecuta al pulsar sobre ingresarBtn



/**
 * =====================================================================
 * Renderer
 */

const RENDER = new THREE.WebGLRenderer({
  canvas: CANVAS,
  antialias: true,
});
RENDER.shadowMap.enabled = true;
RENDER.shadowMap.type = THREE.PCFSoftShadowMap;
//RENDER.outputColorSpace = THREE.SRGBColorSpace;
RENDER.setSize(SIZES.width, SIZES.height);
RENDER.setPixelRatio(Math.min(window.devicePixelRatio, 2));
RENDER.setClearColor("#efefef");
// RENDER.toneMapping = LinearToneMapping;
// RENDER.gammaFactor = 5.2;
// RENDER.gammaOutput = true;
// RENDER.physicallyCorrectLights = true
// RENDER.toneMapping = THREE.ReinhardToneMapping // le da fuerza a la luz
RENDER.toneMapping = THREE.ACESFilmicToneMapping;
RENDER.toneMappingExposure = 0.6; // le da fuerza a la luz

// =============== end render section =================================

// Add individual objects to the carousel group
objects.forEach((object) => {
  carouselGroup.add(object);
});

// Add the carousel group to the scene
SCENE.add(carouselGroup);

carouselGroup.children.forEach((child, index) => {
  console.log(`Object ${index + 1} name: ${child.name}`);
});

/**
 * =====================================================================
 * exports
 */
export { RENDER, ORBIT_CONTROLS, objects, carouselGroup };

ANIMATE();
