import {
  ORBIT_CONTROLS,
  RENDER,
  objects,
  speed,
  radius,
  center,
  carouselGroup,
} from "./script";
import {     animateParticles,
  particleSystem,
  particles,
  particleMaterial,} from "./particles";
import { SIZES, CLOCK } from "./others";
import { CAMERA } from "./camera";
import { SCENE } from "./scene";
import { GLTF_LOADER, MIXER1, MIXER2 } from "./models";
import { STATS } from "./helpers";
import { spotlight } from "./lights";
import {
  servicesSection,
  portfolioSection,
  labSection,
  contactSection,
  homeSection,
} from "./carousel";

/**
 * =====================================================================
 * Canvas change RESIZE
 */
function RESIZE_SCREEN() {
  window.addEventListener("resize", function () {
    // Update sizes
    SIZES.width = window.innerWidth;
    SIZES.height = window.innerHeight;

    // Update camera
    CAMERA.aspect = SIZES.width / SIZES.height;
    CAMERA.updateProjectionMatrix();

    RENDER.setSize(SIZES.width, SIZES.height);
    RENDER.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });
}

window.addEventListener("resize", RESIZE_SCREEN);

/**
 * =====================================================================
 * general animatE FUNCTION
 */
let previousTime = 0;

function ANIMATE() {
  const ELAPSED_TIME = CLOCK.getElapsedTime();
  const DELTA_TIME = ELAPSED_TIME - previousTime;
  previousTime = ELAPSED_TIME;

  //DELTA = CLOCK.getDelta();

  if (MIXER1) {
    MIXER1.update(DELTA_TIME);
  }
  if (MIXER2) {
    MIXER2.update(DELTA_TIME);
  }

  // videoTexture1.needsUpdate = true;
  // videoTexture2.needsUpdate = true;

  servicesSection.lookAt(CAMERA.position);
  portfolioSection.lookAt(CAMERA.position);
  labSection.lookAt(CAMERA.position);
  contactSection.lookAt(CAMERA.position);
  homeSection.lookAt(CAMERA.position);

  ORBIT_CONTROLS.update();
  RENDER.render(SCENE, CAMERA);
  window.requestAnimationFrame(ANIMATE);

  //STATS.update();
  animateParticles();

  // Rotate the carousel group
  carouselGroup.rotation.y += 0.01; // Adjust the rotation speed as desired
}

export { RESIZE_SCREEN, ANIMATE };
