
/* ===============================================================
 * load modal contents
 */

// Function to load modal content of the modals
function loadModalContentHome() {
    fetch("static/modals/modalHome.html")
      .then((response) => response.text())
      .then((htmlContent) => {
        const modalContentContainer = document.getElementById("modal-content-1");
        modalContentContainer.innerHTML = htmlContent;
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
  
  function loadModalContentServices() {
    fetch("static/modals/modalServices.html")
      .then((response) => response.text())
      .then((htmlContent) => {
        const modalContentContainer = document.getElementById("modal-content-2");
        modalContentContainer.innerHTML = htmlContent;
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
  
  function loadModalContentPortfolio() {
    fetch("static/modals/modalPortfolio.html")
      .then((response) => response.text())
      .then((htmlContent) => {
        const modalContentContainer = document.getElementById("modal-content-3");
        modalContentContainer.innerHTML = htmlContent;
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
  
  function loadModalContentLab() {
    fetch("static/modals/modalLab.html")
      .then((response) => response.text())
      .then((htmlContent) => {
        const modalContentContainer = document.getElementById("modal-content-4");
        modalContentContainer.innerHTML = htmlContent;
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
  
  function loadModalContentContact() {
    fetch("static/modals/modalContact.html")
      .then((response) => response.text())
      .then((htmlContent) => {
        const modalContentContainer = document.getElementById("modal-content-5");
        modalContentContainer.innerHTML = htmlContent;
      })
      .catch((error) => {
        console.error("Error loading modal content:", error);
      });
  }
  
  /* =======================================================================
   * endo of modal contents
   */

  export {
    loadModalContentHome,
    loadModalContentServices,
    loadModalContentPortfolio,
    loadModalContentLab,
    loadModalContentContact,
  };