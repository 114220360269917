import * as THREE from 'three';

/**
 * SIZES
 */
let SIZES = {
    width: window.innerWidth,
    height: window.innerHeight
};

/**
 * canvas
 */
const CANVAS = document.querySelector('canvas.webgl');

/**
 * CLOCK
 */
const CLOCK = new THREE.Clock();

export {
    SIZES,
    CANVAS,
    CLOCK
};

