import * as THREE from 'three';

const SCENE = new THREE.Scene();
const fog = new THREE.Scene();

//-------- FOG and background

var setcolor1 = 0xefefef;
var setcolor2 = 0x666666;
var setcolor3 = 0xafd6ff;

SCENE.background = new THREE.Color(setcolor1); // background
// SCENE.fog = new THREE.Fog(setcolor2, 0.20, 50); // near, far
// SCENE.fog = new THREE.FogExp2(setcolor3, 0.115); // a touch of color blue

export {
    SCENE, fog
};