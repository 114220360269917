import "./style.css";
import * as THREE from "three";
import { CAMERA } from "./camera";

const AUDIO_LISTENER = new THREE.AudioListener();
CAMERA.add(AUDIO_LISTENER);

const audioLoader = new THREE.AudioLoader();
const sound = new THREE.Audio(AUDIO_LISTENER);

let dataArray;
let audioSource;
let volume = 0.5;

//--------------------------------

// Define an object to store the play state for each object
const soundPlayed = {
  servicesSection: false,
  portfolioSection: false,
  labSection: false,
  contactSection: false,
  homeSection: false,
};

// Function to play the sound for an object roll over
function playSound(objectText) {
  if (!soundPlayed[objectText]) {
    const audio = new Audio("static/audio/over.mp3");
    audio.play();
    soundPlayed[objectText] = true;
  }
}

//---------------- FX para botones y otros

//////// sonidos

// Detectar y asignar sonidos a los objetos click
let isPlaying1 = false;
let isPlaying2 = false;

function objAudio1() {
  audioLoader.load("static/audio/open.mp3", (buffer) => {
    const sound = new THREE.Audio(AUDIO_LISTENER); // Create a new Audio object
    sound.setBuffer(buffer);
    sound.setLoop(false);
    sound.setVolume(0.3);
    sound.play();
    isPlaying1 = true;
  });
}

function objAudio2() {
  // sonido para el close de las modales
  audioLoader.load("static/audio/close.mp3", (buffer) => {
    const sound2 = new THREE.Audio(AUDIO_LISTENER); // Create a new Audio object
    sound2.setBuffer(buffer);
    sound2.setLoop(false);
    sound2.setVolume(0.3);
    sound2.play();
    isPlaying2 = true;
  });
}



export {
  playSound,
  sound,
  AUDIO_LISTENER,
  objAudio1,
  objAudio2,
  soundPlayed,
};
