import * as THREE from "three";
import { SIZES, CANVAS } from "./others";
import { SCENE, fog } from "./scene";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

/**
 * =====================================================================
 * CAMERA
 */

const CAMERA = new THREE.PerspectiveCamera(
  75,
  SIZES.width / SIZES.height, // necesito sizes
  0.1,
  100
);

CAMERA.position.set(0.5, 1.5, 10);
// CAMERA.fov = 60;
CAMERA.lookAt(SCENE.position);

/**
 * =====================================================================
 * OrbitControls
 */
const ORBIT_CONTROLS = new OrbitControls(CAMERA, CANVAS);
ORBIT_CONTROLS.enableDamping = true;

//autorotate
// ORBIT_CONTROLS.rotateSpeed = 1.5;
// ORBIT_CONTROLS.autoRotate = true;
//ORBIT_CONTROLS.autoRotateSpeed = 1.5;
ORBIT_CONTROLS.maxPolarAngle = Math.PI / 2.3; // Restrict rotation below the y-axis
ORBIT_CONTROLS.minPolarAngle = Math.PI / 5; // Restrict rotation below the y-axis

//ORBIT_CONTROLS.enableRotate = false; // Disable rotation
ORBIT_CONTROLS.enablePan = true; // Disable panning
ORBIT_CONTROLS.enableZoom = true; // Enable zooming

//ORBIT_CONTROLS.minAzimuthAngle = Math.PI / 2; // Restrict the minimum azimuthal angle
//ORBIT_CONTROLS.maxAzimuthAngle = Math.PI / 2; // Restrict the maximum azimuthal angle
//ORBIT_CONTROLS.minPolarAngle = Math.PI / 2; // Restrict the minimum polar angle
//ORBIT_CONTROLS.maxPolarAngle = Math.PI / 2; // Restrict the maximum polar angle

// min and max distant from the centr
var minDistance = 10; // Minimum distance from the center
ORBIT_CONTROLS.minDistance = minDistance; // Set the minimum distance
var maxDistance = 12; // Max distance from the center
ORBIT_CONTROLS.maxDistance = maxDistance; // Set the minimum distance

ORBIT_CONTROLS.target.set(0, 0, 0);

export { CAMERA, ORBIT_CONTROLS };
