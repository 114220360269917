import * as THREE from "three";
import { SCENE } from "./scene";
import * as dat from 'dat.gui';
import 'dat.gui/build/dat.gui.css';


// dat gui renderer background
// const gui = new dat.GUI({
//   width: 320,
// });
//gui.close();
// gui.add(AMBIENT_LIGHT, 'intensity').min(0).max(2).step(0.01)
// gui.add(DIRECTIONAL_LIGHT, 'intensity').min(0).max(1).step(0.01)


const AMBIENT_LIGHT = new THREE.AmbientLight(0xefefef, 1.1);

const DIRECTIONAL_LIGHT = new THREE.DirectionalLight(0xefefef, 0.7);
DIRECTIONAL_LIGHT.castShadow = true;
DIRECTIONAL_LIGHT.shadow.mapSize.set(1024, 1024);
DIRECTIONAL_LIGHT.shadow.normalBias = 0.25;

DIRECTIONAL_LIGHT.shadow.camera.far = 15;  /// ver de 10 a 50
//DIRECTIONAL_LIGHT.shadow.camera.near = 10;

DIRECTIONAL_LIGHT.shadow.camera.left = - 20;
DIRECTIONAL_LIGHT.shadow.camera.top = 20;
DIRECTIONAL_LIGHT.shadow.camera.right = 20;
DIRECTIONAL_LIGHT.shadow.camera.bottom = - 20;

DIRECTIONAL_LIGHT.position.set(0, 5, 7);  /////////// ver posicion para sombras usar gui


export {
    AMBIENT_LIGHT,
    DIRECTIONAL_LIGHT,
}
